<template>
  <b-card>
    <g-form  @submit="save">
      <b-row>
        <b-col md="4">
          <b-button
            :variant="file.name ? 'success' : 'primary'"
            data-action-type="new"
            class="mr-1 btn-sm"
            @click="
              (v) => {
                openModal();
              }
            "
          >
            {{ file.name ? $t("hasAttachMents") : $t("attachFile") }}
            <feather-icon icon="UploadIcon" size="15" class="ml-25" />
          </b-button>
          <a
            :href="selectedItem.attachmentUrl"
            @click.prevent="downloadItem(selectedItem)"
            v-if="selectedItem.attachmentUrl"
            class="mr-1 btn-sm btn btn-success"
          >
            {{ $t("attachedFile") }}
            <feather-icon icon="DownloadIcon" size="15" class="ml-25" />
          </a>
        </b-col>

        <b-col cols="12" class="d-flex justify-content-center mb-2">
          <!-- media -->
          <b-media no-body>
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  rounded
                  height="80"
                  width="80"
                  :src="url"
                />
              </b-link>
              <!--/ avatar -->
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.imgupload.$el.click()"
              >
                {{ $t("change") }}
              </b-button>
              <b-form-file
                @change="onFileChanged"
                ref="imgupload"
                accept=".jpg, .png, .gif"
                :hidden="true"
                plain
              />
              <!--/ upload button -->
            </b-media-body>
          </b-media>
          <!--/ media -->
        </b-col>
      </b-row>
      <!-- static data -->
      <b-row>
        <!-- code  -->
        <b-col md="4">
            <!-- code  -->
            <g-field
              id="code"
              type="number"
              :value.sync="selectedItem.code"
              name="code"
              label-text="code"
            />
        </b-col>
        <!-- arabic name  -->
        <b-col md="4">
            <!-- arabicName  -->
          <g-field
            :value.sync="selectedItem.arabicName"
            rules="required"
            label-text="arabicName"
          />
        </b-col>
        <b-col md="4" >
          <g-field
            ref="englishName"
            :value.sync="selectedItem.englishName"
            label-text="englishName"
          />
        </b-col>
      </b-row>
      <b-tabs  content-class="pt-1" pills fill v-model="tabIndex">
        <b-tab :title="$t('personalData')" :active="tabIndex === 0">
          <b-card border-variant="primary">
            <b-row>
              <b-col md="4">
                <!-- hireDate  -->
                <g-picker
                  :value.sync="selectedItem.hireDate"
                  label-text="hireDate"
                  name="hireDate"
                />
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.gender"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="gender"
                    rules="required"
                    field="select"
                    name="gender"
                    :options="genders"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.basicSalary"
                  name="basicSalary"
                  rules="required"
                  type="number"
                  label-text="basicSalary"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4" >
                <label style="font-size: 14px;margin-bottom: 7px;" for="phone">
                  {{ $t('phone') }}
                </label>
                <b-form-input v-model="selectedItem.mobileNumber" type="number"></b-form-input>
              </b-col>
              <b-col md="4">
                <g-field
                  id="email"
                  :value.sync="selectedItem.email"
                  name="email"
                  rules="email"
                  label-text="email"
                />
              </b-col>
              <b-col md="4" >
                <g-field
                  ref="passportNumber"
                  :value.sync="selectedItem.passportNumber"
                  label-text="passportNumber"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <!-- passportExpirationDate  -->
                <label class="dateLabel" for="example-datepicker2">{{
                  $t('passportExpirationDate')
                }}</label>
                <b-form-datepicker
                  id="example-datepicker2"
                  v-model="selectedItem.passportExpirationDate"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  class="mb-2"
                ></b-form-datepicker>
              </b-col>
              <b-col md="4" >
                <label style="font-size: 14px;margin-bottom: 7px;" for="phone">
                  {{ $t('residenceNumber') }}
                </label>
                <b-form-input v-model="selectedItem.residenceNumber" type="number"></b-form-input>
              </b-col>
              <b-col md="4">
                <!-- residenceExpirationDate  -->
                <label class="dateLabel" for="example-datepicker3">{{
                  $t('residenceExpirationDate')
                }}</label>
                <b-form-datepicker
                  id="example-datepicker3"
                  v-model="selectedItem.residenceExpirationDate"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  class="mb-2"
                ></b-form-datepicker>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.departmentId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="department"
                    rules="required"
                    field="select"
                    name="departments"
                    :options="departments"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.divisionId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="division"
                    rules="required"
                    field="select"
                    name="divisions"
                    :options="divisions"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.qualificationId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="qualification"
                    rules="required"
                    field="select"
                    name="qualification"
                    :options="qualifications"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.jobId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="job"
                    rules="required"
                    field="select"
                    name="jobs"
                    :options="jobs"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.religionId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="religion"
                    rules="required"
                    field="select"
                    name="religions"
                    :options="religions"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.maritalStatusId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="maritalStatus"
                    rules="required"
                    field="select"
                    name="maritalStatus"
                    :options="maritalStatus"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.nationalityId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="nationality"
                    rules="required"
                    field="select"
                    name="nationality"
                    :options="nationalities"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <g-field
                  id="jobNumber"
                  :value.sync="selectedItem.jobNumber"
                  name="jobNumber"
                  label-text="jobNumber"
                />
              </b-col>
              <b-col
                md="4"
              >
                <label
                  style="font-size: 14px; margin-bottom: 6px;"
                >
                  {{ $t("employeeWorkSituation") }}
                </label>
                <b-form-radio-group
                  v-model="selectedItem.isSuspended"
                  class="work-status-group"
                  button-variant="outline-primary"
                  :options="employeeSuspentionStatus"
                  size="md"
                  name="radios-btn-default"
                />
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    field="select"
                    label="arabicName"
                    label-text="accountingLinkage"
                    name="accountId"
                    :value.sync="selectedItem.accountId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    :options="accounts"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <label style="font-size: 14px;margin-bottom: 7px;" for="isCustodyResponsible">
                  {{ $t('isDepartment') }}
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.isDepartment"
                    class="custom-control-primary"
                  />
                </b-form-group>
              </b-col>

              <b-col md="2">
                <label
                  style="font-size: 14px; margin-bottom: 7px"
                  for="general"
                >
                  {{ $t("general") }}
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.isShared"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-tab>
        <!-- BenefitsAndDeductions -->
        <b-tab :title="$t('BenefitsAndDeductions')" :active="tabIndex === 1">
          <b-card border-variant="primary">
            <b-row>
              <!-- salaryItems -->
              <b-col  md="3" >
                <b-form-group>
                  <g-field
                    v-model="selectedItem.salaryItem"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    field="select"
                    name="salaryItems"
                    :options="salaryItems"
                    :label="isRight ? 'arabicName' : 'englishName'"
                    label-text="salaryItems"
                    @change="
                (v) => {
                  updateNature(v);
                  }
                  "
                  />
                </b-form-group>
              </b-col>
               <!-- nature -->
        <b-col md="3">
          <b-form-group>
            <g-field
              field="select"
              label-text="nature"
              name="nature"
              label="arabicName"
              :dir="isRight ? 'rtl' : 'ltr'"
              :options="salaryItemNatures"
              :value.sync="nature"
              disabled
            />
          </b-form-group>
        </b-col>
        <!-- value -->
        <b-col md="3">
          <g-field
            id="value"
            :value.sync="selectedItem.value"
            :disabled="!selectedItem.salaryItem || selectedItem.salaryItem === 0"
            name="value"
            label-text="value"
            @input="
              (v) => {
                validateData(v);
                caculateBenefitsAndDeduction(v,false);
              }
            "
          />
        </b-col>
        <!-- total -->
        <b-col md="3">
          <g-field
            id="total"
            :value.sync="total"
            name="total"
            label-text="total"
            disabled
          />
        </b-col>
            <b-col cols="12" class="d-flex justify-content-end mb-50">
                <b-button
                  @click="
                    () => {
                      addSalaryItems(selectedItem.salaryItem);
                      this.selectedItem.salaryItem = 0;
                      this.selectedItem.value = null;
                      this.nature = '';
                      this.total = 0;
                    }
                  "
                  variant="primary"
                >
                  {{ $t('addService') }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="medium-window">
                <b-table
                  ref="b-table"
                  :items="selectedItem.salaryItems"
                  :fields="salaryTableColumns"
                  perPage="25"
                  small
                  show-empty
                  hover
                  stickyColumn
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="isSortDirDesc"
                  :sort-direction="sortDirection"
                  class="mb-0"
                  :empty-text="$t('noMatchingRecordsFound')"
                >
                <template #head(actions)>
                  <span></span>
                </template>
                <template #cell(value)="{ item }">
                  <g-field
                  class="mb-0 m-auto text-center"
                    :value.sync="item.value"
                    type="number"
                    rules="required"
                    :shortDesc="true"
                    size="sm"
                    :style="{ width: '100px' }"
                    @input="
                    (v) => {
                      computeTotal(item);
                      }"
                      />
                </template>
                <template #cell(actions)="{ item }">
                    <div class="text-nowrap">
                      <b-button
                        v-b-tooltip.hover.bottom="$t('delete')"
                        variant="flat-danger"
                        size="sm"
                        @click="removeSalaryItemsFromTable(item)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          stroke="red"
                          class="mx-0 clickable danger"
                          :id="`invoice-row-${item.id}-delete-icon`"
                        />
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
        </b-tab>
        <b-tab :title="$t('information')" :active="tabIndex === 2">
          <b-card border-variant="primary">
            <b-row>
              <!-- follower information  -->
              <b-col md="4">
                <g-field
                  :value.sync="affiliateInformation.affiliateName"
                  label-text="affiliateName"
                />
              </b-col>
              <b-col md="4">
                <g-field
                  :value.sync="affiliateInformation.residencyNumber"
                  label-text="residencyNumber"
                />
              </b-col>
              <b-col md="4">
                <g-field
                  :value.sync="affiliateInformation.contactNumber"
                  label-text="contactNumber"
                  rules="digits:10"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="affiliateInformation.gender"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="gender"
                    field="select"
                    name="gender"
                    :options="genders"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <g-field
                    :value.sync="affiliateInformation.relativeRelation"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="relativeRelation"
                    field="select"
                    name="relativeRelation"
                    :options="relativeRelation"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" class="d-flex justify-content-end mb-50">
                <b-button
                  @click="
                    () => {
                      addaffiliateInformation(affiliateInformation);
                    }
                  "
                  variant="primary"
                >
                {{ affiliateInformation.affiliateInformationId > 0 ? $t('editAffiliate') : $t('addAffiliate') }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="medium-window">
                <b-table
                  ref="b-table"
                  :items="selectedItem.affiliateInformations"
                  :fields="informationTableColumns"
                  perPage="25"
                  small
                  show-empty
                  hover
                  stickyColumn
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="isSortDirDesc"
                  :sort-direction="sortDirection"
                  :createButton="{ visiable: true, text: affiliateInformation.affiliateInformationId > 0 ? 'editAffiliateInformation' : 'addaffiliateInformation' }"
                  class="mb-0"
                  :empty-text="$t('noMatchingRecordsFound')"
                >
                <template #head(actions)>
                  <span></span>
                </template>
                <template #cell(actions)="{ item }">
                    <div class="text-nowrap">

                      <b-button
                        v-b-tooltip.hover.bottom="$t('edit')"
                        variant="flat-secondary"
                        class="btn-icon"
                        size="sm"
                        @click="editAffiliateInformation(item)"
                      >
                        <feather-icon
                        :id="`invoice-row-${item.id}-preview-icon`"
                        icon="EditIcon"
                        class="mx-0 clickable"
                      />
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.bottom="$t('delete')"
                        variant="flat-danger"
                        size="sm"
                        @click="removeAffiliateInformation(item)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          stroke="red"
                          class="mx-0 clickable danger"
                          :id="`invoice-row-${item.affiliateInformationId}-delete-icon`"
                        />
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
        </b-tab>
      </b-tabs>
      <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button class="mx-1" type="submit" variant="primary" data-action-type="save" v-permission="$route.meta.permission">
            {{ selectedItem.id > 0 ? $t('edit') : $t('save') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
    <b-modal
      no-close-on-backdrop
      ref="upload-modal"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      hide-footer
      :title="$t('attachFile')"
    >
      <g-form>
        <b-row>
          <b-col cols="12">
            <div>
              <input
                type="file"
                class="excel-upload-input"
                @change="handleFileUpload($event)"
                accept=".pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip"
              />
              <b-alert
                v-if="file"
                show
                fade
                class="mt-2 text-center"
                variant="success"
              >
                <div class="alert-body">
                  <span>{{ $t("importSuccsefly") }} {{ file.name }}</span>
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr />
          </b-col>
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button class="mx-1" variant="primary" @click="closeModal">
              {{ $t("save") }}
            </b-button>
            <b-button
              class="mx-1"
              @click="
                () => {
                  removeAttachment();
                }
              "
              variant="danger"
              data-action-type="delete"
              :disabled="!file"
            >
              {{ $t("cancel") }}
            </b-button>
          </b-col>
        </b-row>
      </g-form>
    </b-modal>
  </b-card>
</template>

<script>
import { genders, relativeRelation, salaryItemNatures } from '@/libs/acl/Lookups';
import saveAs from 'file-saver';

export default {
  props: ['id'],
  data() {
    return {
      total: 0,
      nature: '',
      itemIndex: 0,
      tabIndex: 0,
      url: '',
      file: '',
      sortBy: 'id',
      totalRows: 0,
      currentPage: 1,
      isTableBusy: false,
      perPage: 25,
      filterOn: [],
      isSortDirDesc: false,
      sortDirection: 'asc',
      searchQuery: '',
      selectedItem: {
        salaryItems: [],
        affiliateInformations: [],
        code: '',
        branchId: this.currentBranchId,
        arabicName: '',
        englishName: '',
        hireDate: this.today,
        basicSalary: 0,
        nationalityId: null,
        nationalityCode: null,
        nationalityArabicName: null,
        nationalityEnglishName: null,
        departmentId: null,
        departmentCode: null,
        departmentArabicName: null,
        departmentEnglishName: null,
        qualificationId: null,
        qualificationCode: null,
        qualificationArabicName: null,
        qualificationEnglishName: null,
        divisionId: null,
        divisionCode: null,
        divisionArabicName: null,
        divisionEnglishName: null,
        maritalStatusId: null,
        maritalStatusCode: null,
        maritalStatusArabicName: null,
        maritalStatusEnglishName: null,
        religionId: null,
        religionCode: null,
        religionArabicName: null,
        religionEnglishName: null,
        jobId: 0,
        jobCode: 0,
        jobArabicName: '',
        jobEnglishName: '',
        jobNumber: null,
        mobileNumber: '',
        email: '',
        passportNumber: '',
        passportExpirationDate: null,
        residenceNumber: '',
        residenceExpirationDate: null,
        isCustodyResponsible: false,
        isSuspended: false,
        isShared: false
    },
      genders: genders,
      departments: [],
      divisions: [],
      qualifications: [],
      jobs: [],
      religions: [],
      accounts: [],
      nationalities: [],
      maritalStatus: [],
      salaryItems: [],
      affiliateInformation: {
        itemIndex: 0,
      },
      relativeRelation: relativeRelation,
      salaryItemNatures: salaryItemNatures
    };
  },
  computed: {
    salaryTableColumns() {
      return [
        {
          key: 'code',
          label: this.$t('code'),
          sortable: true,
        },
        {
          key: this.isRight ? 'arabicName' : 'englishName',
          label: this.$t('name'),
          sortable: true,
        },
        {
          key: 'itemType',
          label: this.$t('type'),
          sortable: true,
          formatter: (key, value, item) => {
            return this.$t(item.itemType);
          },
        },
        {
          key: 'nature',
          label: this.$t('nature'),
          sortable: true,
          formatter: (key, value, item) => {
            return this.$t(item.nature);
          },
        },
        {
          key: 'value',
          label: this.$t('value'),
          sortable: true,
          type: 'number'
        },
        {
          key: 'total',
          label: this.$t('total'),
          sortable: true,
          type: 'number'
        },
        {
          key: 'actions',
          sortable: false
        },
      ];
    },
    informationTableColumns() {
      return [
        {
          key: 'affiliateName',
          label: this.$t('affiliateName'),
          sortable: true,
        },
        {
          key: 'residencyNumber',
          label: this.$t('residencyNumber'),
          sortable: true,
        },
        {
          key: 'contactNumber',
          label: this.$t('contactNumber'),
          sortable: true,
        },
        {
          key: 'gender',
          label: this.$t('gender'),
          sortable: true,
          formatter: (key, value, item) => {
            return this.$t(item.gender);
          },
        },
        {
          key: 'relativeRelation',
          label: this.$t('relativeRelation'),
          sortable: true,
          formatter: (key, value, item) => {
            return this.$t(item.relativeRelation);
          },
        },
        {
          key: 'actions',
          sortable: false
        },
      ];
    },
    discountsTableColumns() {
      return [
        {
          key: this.isRight ? 'arabicName' : 'englishName',
          label: this.$t('name'),
          sortable: true,
        },
        {
          key: 'value',
          label: this.$t('value'),
          sortable: true,
          type: 'number'
        },
        {
          key: 'notes',
          label: this.$t('notes'),
          sortable: false,
        },
        { key: 'actions' },
      ];
    },
    employeeSuspentionStatus() {
      return [
        { text: this.$t('suspendedFromWork'), value: true },
        { text: this.$t('startWork'), value: false },
      ];
    }
  },
  mounted() {
    this.url = 'employee_default_image.png';
    this.selectedItem.hireDate = this.today;
    this.selectedItem.transactionDate = this.today;
    this.loadData();
    this.getAccounts();
    if (this.id > 0) {
      this.getData();
    }
  },
  methods: {
    getAccounts() {
      this.get({ url: 'accounts' }).then((data) => {
        this.accounts = data.filter((acc) => acc.type === 'sub');
      })
    },
    computeTotal(item) {
      // this.nature = item.nature;
      this.caculateBenefitsAndDeduction(item, true);
    },
    validateData(v) {
      if (v <= 0) {
        this.doneAlert({ text: this.$t('valueMustBeGreaterThanZero'), type: 'warning', timer: 10000 });
        return;
      }
      if (this.selectedItem.basicSalary <= 0) {
        this.doneAlert({ text: this.$t('mustAddSalaryBeforeValue'), type: 'warning', timer: 10000 });
        this.selectedItem.value = 0;
        this.total = 0;
        return false;
      }
      if (this.nature === 'percentage' && v > 100) {
        this.doneAlert({ text: this.$t('percentageValueMustbeBetween1And100'), type: 'warning', timer: 10000 });
        return false;
      }
    },
    // calcuate benefit and deduction for employee
    caculateBenefitsAndDeduction(item, edit) {
   if (edit) {
    if (item.nature === 'days') {
      item.total = (
          ((this.selectedItem.basicSalary * 12) / 365) * item.value).toFixed(2);
      } else if (item.nature === 'value') {
        item.total = item.value;
      } else if (item.nature === 'hours') {
        item.total = (
          ((this.selectedItem.basicSalary * 12) / 365 / 8) * item.value).toFixed(2);
      } else {
        item.total = ((this.selectedItem.basicSalary / 100) * item.value).toFixed(
          2
        );
      }
   }
   if (!edit) {
      if (this.nature === 'days') {
        this.total = (
          ((this.selectedItem.basicSalary * 12) / 365) * item).toFixed(2);
      } else if (this.nature === 'value') {
        this.total = item;
      } else if (this.nature === 'hours') {
        this.total = (
          ((this.selectedItem.basicSalary * 12) / 365 / 8) * item).toFixed(2);
      } else {
        this.total = ((this.selectedItem.basicSalary / 100) * item).toFixed(
          2
        );
      }
    }
    },
    updateNature(salaryItem) {
      this.nature = salaryItem.nature;
      this.selectedItem.value = 0;
      this.total = 0;
    },
    closeModal() {
      this.$refs['upload-modal'].hide();
    },
    openModal() {
      this.$refs['upload-modal'].show();
    },
    removeAttachment() {
      this.selectedItem.attachmentFileExtension = '';
      this.selectedItem.attachmentBase64Content = '';
      this.selectedItem.attachmentFileName = '';
      this.file = '';
      this.closeModal();
    },
    downloadItem(item) {
      const path = item.attachmentUrl.split('.')[1];
      saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
    },
    onFileChanged(event) {
      const file = event.target.files[0];
      this.selectedItem.imageFileExtension = file.name.split('.').pop();
      this.toBase64(file)
        .then((file1) => {
          this.selectedItem.imageBase64Content = file1.split(',').pop();
        });
      this.url = URL.createObjectURL(file);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      const fileSize = this.file.size / 1024 / 1024;
      this.selectedItem.attachmentFileExtension = this.file.name.split('.').pop();
      this.toBase64(this.file).then((file1) => {
        this.selectedItem.attachmentBase64Content = file1.split(',').pop();
      });
      this.selectedItem.attachmentFileName = URL.createObjectURL(this.file);
      if (!this.isValidType(this.file)) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({ text: this.$t('Only supports upload .pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip suffix files'), type: 'error' });
        this.file = '';
        return false
      }
      if (this.isValidType(this.file) && fileSize > 5) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({ text: this.$t('fileSizeExceedsFiveMiB'), type: 'error' });
        this.file = '';
        return false
      }
    },
    isValidType(file) {
      return /\.(pdf|jpg|jpeg|bmp|png|doc|docx|zip)$/.test(file.name)
    },

    birthDatetoHijriDate(date) {
      this.get({ url: `Utilities/date/ToHijri?value=${date}` }).then((data) => {
        this.selectedItem.hijriBirthDate = data;
      });
    },
    birthDateToGregorian(date) {
      this.get({ url: `Utilities/date/ToGregorian?value=${this.getDate(date)}` }).then((data) => {
        this.selectedItem.birthDate = this.getDate(data);
      });
    },
    registrationDatetoHijriDate(date) {
      this.get({ url: `Utilities/date/ToHijri?value=${date}` }).then((data) => {
        this.selectedItem.hijriRegistrationDate = data;
      });
    },
    registrationDateToGregorian(date) {
      this.get({ url: `Utilities/date/ToGregorian?value=${this.getDate(date)}` }).then((data) => {
        this.selectedItem.registrationDate = this.getDate(data);
      });
    },
    loadData() {
      this.get({ url: 'departments' }).then((data) => {
        this.departments = data;
      })
      .then(() => {
        this.get({ url: 'divisions' }).then((data) => {
        this.divisions = data;
      });
      })
      .then(() => {
        this.get({ url: 'qualifications' }).then((data) => {
        this.qualifications = data;
      });
      })
      .then(() => {
        this.get({ url: 'jobs' }).then((data) => {
        this.jobs = data;
      });
      })
      .then(() => {
        this.get({ url: 'religions' }).then((data) => {
        this.religions = data;
      });
      })
      .then(() => {
        this.get({ url: 'maritalStatuses' }).then((data) => {
        this.maritalStatus = data;
      });
      })
      .then(() => {
        this.get({ url: 'nationalities' }).then((data) => {
        this.nationalities = data;
      });
      })
      .then(() => {
        this.get({ url: 'salaryItems' }).then((data) => {
        this.salaryItems = data;
      });
      });
    },
    getData() {
      this.get({ url: 'employees', id: this.id }).then((data) => {
        this.selectedItem = data;
        this.url = this.selectedItem.imageUrl
          ? `${this.domain}${this.selectedItem.imageUrl}`
          : 'employee_default_image.png';
      });
    },
    addSalaryItems() {
      let errorMsg = '';
      if (this.selectedItem.value <= 0) errorMsg = this.$t('valueMustBeGreaterThanZero');
      if (errorMsg) {
        this.doneAlert({
          text: errorMsg,
          type: 'error',
        });
      } else {
        const res = this.salaryItems.find((v) => v.id === this.selectedItem.salaryItem);
        var exists = this.selectedItem.salaryItems.find((v) => v.salaryItemId === res.id);
        if (exists) {
          this.doneAlert({
          text: this.$t('cannotAddBenefitOrDeductionMoreThanOneToEmployee'),
          type: 'error',
        });
        return;
        }
        this.salaryData = {};
        this.salaryData.salaryItemId = res.id;
        this.salaryData.value = this.selectedItem.value;
        this.salaryData.total = this.total;
        this.salaryData.itemType = res.itemType;
        this.salaryData.nature = res.nature;
        this.salaryData.code = res.code;
        this.tenantId = res.tenantId;
        this.salaryData.arabicName = res.arabicName;
        this.salaryData.englishName = res.englishName;
        this.selectedItem.salaryItems.push({ ...this.salaryData });
      }
      return false;
    },

    isMobilePattern(itemVal) {
      if (!itemVal) return true;

      itemVal = itemVal.trim();
      for (let index = 0; index < itemVal.length; index++) {
        const currentChar = itemVal[index];
        if (!/^\d$/.test(currentChar) && currentChar !== '+' && currentChar !== '-') {
          return false;
        }
      }
      return true
    },

    addaffiliateInformation(item) {
      // validate item
      if (!item.affiliateName) {
        this.doneAlert({ text: this.$t('affiliateNameIsRequired'), type: 'warning' });
        return false;
      }

      if (!this.isMobilePattern(item.residencyNumber)) {
        this.doneAlert({ text: `${this.$t('residencyNumber')} ${this.$t('mustMatchMobilePattern')}`, type: 'warning', timer: 5000 });
        return;
      }

      if (!this.isMobilePattern(item.contactNumber)) {
        this.doneAlert({ text: `${this.$t('contactNumber')} ${this.$t('mustMatchMobilePattern')}`, type: 'warning', timer: 5000 });
        return;
      }

      if (item.contactNumber && item.contactNumber.length !== 10) {
        return;
      }

      if (item.affiliateInformationId > 0 || item.itemIndex > 0) {
        var index = '0';
        if (item.affiliateInformationId > 0) {
          index = this.selectedItem.affiliateInformations.findIndex((s) => s.affiliateInformationId === item.affiliateInformationId);
        } else {
          index = this.selectedItem.affiliateInformations.findIndex((s) => s.itemIndex === item.itemIndex);
        }
        this.affiliateInformation.affiliateName = item.affiliateName;
        this.affiliateInformation.residencyNumber = item.residencyNumber;
        this.affiliateInformation.contactNumber = item.contactNumber;
        this.affiliateInformation.gender = item.gender;
        this.affiliateInformation.relativeRelation = item.relativeRelation;
        this.$set(this.selectedItem.affiliateInformations, index, this.affiliateInformation)
        this.initStore();
      } else {
        this.itemIndex += 1;
        this.selectedItem.affiliateInformations.push({
          itemIndex: this.itemIndex,
          affiliateName: item.affiliateName,
          residencyNumber: item.residencyNumber,
          contactNumber: item.contactNumber,
          gender: item.gender,
          relativeRelation: item.relativeRelation,
      });
      this.initStore();
    }
  },
    editAffiliateInformation(item) {
      item.id = item.affiliateInformationId
      this.affiliateInformation = { ...item }
      this.affiliateInformation.affiliateInformationId = item.affiliateInformationId;
      this.affiliateInformation.itemIndex = item.itemIndex;
    },
    addDiscounts(id) {
      let errorMsg = '';
      if (this.selectedItem.discounts.find((v) => v.discountId === id)) errorMsg = this.$t('canNotRepeatTheSameDiscounts');
      if (errorMsg) {
        this.doneAlert({
          text: errorMsg,
          type: 'error',
        });
      } else {
        const res = this.discounts.find((v) => v.id === this.selectedItem.discountId);
        this.discountData = res
        this.discountData.discountId = res.id;
        this.discountData.originalValue = res.value;
        this.selectedItem.discounts.push({ ...this.discountData });
      }
      return false;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    refreshTable() {
      this.$refs.itemstable.refresh();
    },
    registrationDateCheck() {
      if (!this.selectedItem.registrationDate) {
        this.doneAlert({ text: this.$t('registrationDateIsRequired'), type: 'error' });
        return false;
      }
      return true;
    },
    birthDateCheck() {
      if (!this.selectedItem.birthDate) {
        this.doneAlert({ text: this.$t('birthDateIsRequired'), type: 'error' });
        return false;
      }
      return true;
    },
    save() {
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'employees',
          data: this.selectedItem,
          id: this.selectedItem.id,
        })
          .then(() => {
            this.doneAlert({ text: this.$t('updatedSuccessfully') });
            this.$router.push({ name: 'employees-list' });
          });
      } else {
        this.create({
          url: 'employees',
          data: this.selectedItem,
        })
          .then(() => {
            this.doneAlert({ text: this.$t('savedSuccessfully') });
            this.$router.push({ name: 'employees-list' });
          });
      }
    },
    removeSalaryItemsFromTable(salaryItem) {
      this.selectedItem.salaryItems = this.selectedItem.salaryItems.filter((item) => item !== salaryItem);
    },
    removeAffiliateInformation(affiliateInformation) {
      this.selectedItem.affiliateInformations = this.selectedItem.affiliateInformations.filter((item) => item !== affiliateInformation);
    },
    removeDescountFromTable(discount) {
      this.selectedItem.discounts = this.selectedItem.discounts.filter((item) => item !== discount);
    },
    itemsSearchProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;
      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      let params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

      params += this.getFilterObj(this.filter);

      this.isTableBusy = true;
      this.get({ url: `employees${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount;
          callback(data);
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },
    initStore() {
      this.affiliateInformation = {
        affiliateInformationId: null,
        affiliateName: '',
        residencyNumber: '',
        contactNumber: '',
        gender: '',
        relativeRelation: '',
        isActive: true,
      }
    }
  },
};
</script>

<style>
.resize_img {
  width: 10%;
  border-radius: 10%;
  margin-left: 50%;
  margin-right: auto;
}

.work-status-group {
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: 1px solid #ced4da;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.employee-validator {
  display: block;
  margin-top: -5px;
  color: #ea5455;
}
</style>
